<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>商城订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- <el-row>
        <el-col :span="6">
          <el-input placeholder="请输入内容">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row> -->

      <!-- 订单列表 -->
      <el-table :data="list" id="mainTable" border :height="tableHeight">
        
        <el-table-column width="125px" label="商品图片">
          <template slot-scope="scope"
            ><img :src="scope.row.image" width="100px" height="100px"
          /></template>
        </el-table-column>
        <el-table-column
          label="商品名称"
          width="150px"
          prop="goodsName"
        ></el-table-column>
        <el-table-column label="商品规格" width="150px">
          <template slot-scope="scope">
            <el-tag type="success" size="mini">{{ scope.row.spec }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="商品单价(元)"
          prop="price"
          width="100px"
        >
        <template slot-scope="scope"> <el-tag type="info" size="mini">¥{{ $round(scope.row.price) }} </el-tag></template>
      </el-table-column>
        <el-table-column width="120px" label="当前状态">
          <template slot-scope="scope">
            <el-tag type="default" size="mini">{{
              getStatus(scope.row.status)
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="应付金额(元)" width="100px">
          <template slot-scope="scope">
            <el-tag type="info" size="mini">¥{{
              scope.row.deliveredNumber
                ? Number(
                    scope.row.deliveredNumber * $round(scope.row.price)
                  ).toFixed(2)
                : 0
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="实际已付(元)" width="100px">
          <template slot-scope="scope">
            <el-tag type="success" size="mini">¥{{ Number($round(scope.row.paidAmount) ).toFixed(2) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="预定数量(件)"
          prop="number"
          width="100px"
        >
        <template slot-scope="scope">
            <el-tag type="info" size="mini">{{ scope.row.number }}</el-tag>
        </template>
      </el-table-column>
        <el-table-column
          label="已配送数量(件)"
          prop="deliveredNumber"
          width="120px"
        >
          <template slot-scope="scope">
            <el-tag
              style="cursor: pointer"
              type="success"
              size="mini"
              @click="viewDeliverList(scope.row)"
              >{{ scope.row.deliveredNumber || 0 }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="订单编号"
          width="200px"
          prop="shop_orderNo"
        ></el-table-column>
        <el-table-column label="下单时间" width="150px">
          <template slot-scope="scope">{{
            scope.row.createtime | dataFormat
          }}</template>
        </el-table-column>
        <el-table-column width="200px" label="期望送达时间">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.startDate }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="下单人">
          <template slot-scope="scope">
            <el-tag type="success">{{
              `${scope.row.name}(${scope.row.mobile})`
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="配送地址" width="400px">
          <template slot-scope="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="付款方式"  width="100px">
          <template slot-scope="scope">
            <el-tag type="default" size="mini" v-if="scope.row.status == 4 && scope.row.payments.length">线下支付</el-tag>
            <el-tag type="default" size="mini" v-if="scope.row.status == 4 && scope.row.payment_method != 'offline'">在线支付</el-tag>
            <el-tag type="danger" size="mini" v-if="scope.row.status != 4">未支付</el-tag>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" fixed="right" width="400px">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="changeAddress(scope.row)"
              >修改地址</el-button
            > -->
            <el-button
              v-if="scope.row.status == 0 || scope.row.status == 1"
              type="danger"
              size="mini"
              icon="el-icon-confirm"
              @click="updateStatus(scope.row, -99)"
              >取消订单</el-button
            >
            <el-button
              v-if="scope.row.status == 0"
              type="success"
              size="mini"
              icon="el-icon-confirm"
              @click="updateStatus(scope.row, 1)"
              >确认订单</el-button
            >
            <!-- <el-button
              v-if="scope.row.status == 1"
              type="primary"
              size="mini"
              icon="el-icon-confirm"
              @click="updateStatus(scope.row, 2)"
              >开始配送</el-button
            >
            <el-button
              v-if="scope.row.status == 2 && scope.row.deliveredNumber < scope.row.number"
              type="success"
              size="mini"
              icon="el-icon-confirm"
              @click="updateDeliverProgress(scope.row)"
              >更新配送进度</el-button
            >
            <el-button
              v-if="scope.row.status == 2 && scope.row.deliveredNumber >= scope.row.number"
              type="success"
              size="mini"
              icon="el-icon-confirm"
              @click="updateStatus(scope.row, 3)"
              >完成配送</el-button
            > -->
            <el-button
              v-if="scope.row.deliveredNumber"
              type="default"
              size="mini"
              icon="el-icon-confirm"
              @click="viewDeliverList(scope.row)"
              >查看配送凭证</el-button
            >
            <el-button
              type="default"
              size="mini"
              icon="el-icon-location"
              @click="viewLocation(scope.row)"
              >查看位置</el-button
            >
            <el-button
              type="success"
              size="mini"
              icon="el-icon-confirm"
              v-if="scope.row.status == 3"
              @click="offlinePay(scope.row)"
              >已线下付款</el-button>
              <el-button
              type="success"
              size="mini"
              icon="el-icon-confirm"
             v-if="scope.row.status == 4 && scope.row.payments.length"
              @click="viewOfflinePayEvidence(scope.row)"
              >查看线下付款凭证</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 编辑对话框 -->
    <!-- <el-dialog
      title="修改地址"
      :visible.sync="addressDialogVisible"
      width="50%"
      @close="addressDialogClosed"
    > -->
    <!-- <el-form
        :model="addressForm"
        :rules="addressFormRules"
        ref="addressFormRef"
        label-width="100px"
      > -->
    <!-- <el-form-item label="省市区/县" prop="address1">
          <el-cascader
            v-model="addressForm.address1"
            :options="cityData"
            :props="{ expandTrigger: 'hover' }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address2">
          <el-input v-model="addressForm.address2"></el-input>
        </el-form-item>
      </el-form> -->
    <!-- <AmapChose
        :color="color"
        :keyMap="keyMap"
        :AMapKeyWeb="keyWeb"
        :show="mapShow"
        :initLocation="initLocation"
        @placeChoose="placeChoose"
        @changeShow="changeShow"
      ></AmapChose>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <!-- 展示物流进度对话框 -->
    <el-dialog
      title="发货派送"
      :visible.sync="progressDialogVisible"
      width="50%"
    >
      <!-- 时间线 -->
      <el-timeline v-if="progressInfo && progressInfo.length">
        <el-timeline-item
          v-for="(activity, index) in progressInfo"
          :key="index"
          :timestamp="activity.time"
          >{{ activity.context }}</el-timeline-item
        >
      </el-timeline>
      <el-form
        :model="addressForm"
        :rules="addressFormRules"
        ref="addressFormRef"
        label-width="100px"
      >
        <el-form-item label="选择快递公司">
          <el-select
            v-model="addressForm.expressId"
            :options="expressList"
          ></el-select>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input v-model="addressForm.expressNo"></el-input>
        </el-form-item>
        <el-button type="primary" @click="progressDialogVisible = false"
          >确 定</el-button
        >
      </el-form>
    </el-dialog>
    <!-- 展示物流进度对话框 -->
    <el-dialog
      title="线下付款"
      :visible.sync="offlinePayDialogVisible"
      width="50%"
       @close="offlinePayDialogClosed"
    >
      <el-form
        :model="offlinePayForm"
        ref="offlinePayFormRef"
        label-width="100px">
        <el-form-item label="付款凭证">
          <upload
            name="pay_proof_image"
            :initFileList="initFileList"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="offlinePayForm.remark"
            placeholder="在此输入备注信息"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="offlinePayConfirm">确 定</el-button>
      </el-form>
    </el-dialog>

    <el-dialog
      title="更新配送进度"
      :visible.sync="updateProgressDialogVisible"
      width="40%"
    >
      <el-form
        :model="updateProgressForm"
        ref="updateProgressFormRef"
        label-width="200px"
      >
        <el-form-item label="本次配送数量(件)">
          <el-date-picker
            v-model="updateProgressForm.deliverDate"
            align="right"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="本次配送数量(件)">
          <el-input
            v-model="updateProgressForm.number"
            placeholder="在此输入本次配送的数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="当前剩余配送数量(件)">
          <el-tag
            type="success"
            size="mini"
            style="width: 100px; text-align: center"
            >{{
              currentOrder
                ? currentOrder.number - currentOrder.deliveredNumber
                : 0
            }}</el-tag
          >
        </el-form-item>
        <el-form-item label="发货/签收凭证(图片)">
          <upload
            name="deliver_proof_image"
            :initFileList="initFileList"
            :multiple="isMultiple"
            @handleUploadSuccess="handleUploadSuccess"
            @handleRemove="handleRemove"
          ></upload>
        </el-form-item>
        <el-form-item label="配送人姓名">
          <el-input
            v-model="updateProgressForm.deliverName"
            placeholder="在此输入本次配送人的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="配送人联系方式">
          <el-input
            v-model="updateProgressForm.mobile"
            placeholder="在此输入本次配送人的联系方式"
          ></el-input>
        </el-form-item>
        <el-button
          style="width: 300px; margin: 0 auto; margin-left: 200px"
          type="primary"
          @click="updateDeliverProgressConfirm"
          >确 定</el-button
        >
      </el-form>
    </el-dialog>

    <el-dialog
      title="查看配送记录"
      :visible.sync="deliverProgressDialogVisible"
      width="1200px"
    >
      <el-table :data="deliverProgressList" border stripe>
        <el-table-column width="150px" label="配送凭证">
          <template slot-scope="scope">
            <el-image
              v-for="(item, index) in scope.row.images"
              :key="index"
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="[item]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          width="150px"
          label="配送时间"
          prop="deliverDate"
        >
        <template slot-scope="scope">
           {{ scope.row.deliverDate ? new Date(scope.row.deliverDate).Format("yyyy年MM月dd日") : "--" }}
        </template>
      </el-table-column>
        <el-table-column width="100px" label="配送数量">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.number }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="150px" label="配送人">
          <template slot-scope="scope">
            {{ scope.row.deliverName }}
          </template>
        </el-table-column>
        <el-table-column width="200px" label="配送人联系方式">
          <template slot-scope="scope">
            {{ scope.row.mobile }}
          </template>
        </el-table-column>
        <el-table-column
          width="200px"
          label="更新时间"
          prop="createtime"
        >
        <template slot-scope="scope">
           {{ scope.row.createtime ? new Date(scope.row.createtime).Format("yyyy年MM月dd日 HH:mm:ss") : "--" }}
        </template>
      </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope"> </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :title="mapDialogTitle"
      :visible.sync="addressDialogVisible"
      width="50%"
      @close="addressDialogClosed"
    >
      <AmapChose
        ref="mapChoose"
        :initLocation="initLocation"
        :onlyShowMap="onlyShowMap"
        :show="addressDialogVisible"
        @placeChoose="placeChoose"
        @changeShow="changeShow"
      ></AmapChose>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChooseAddress">取 消</el-button>
        <el-button type="primary" @click="confirmChooseAddress"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="查看线下付款记录"
      :visible.sync="offlinePayEvidenceDialogVisible"
      width="1200px"
    >
    <el-table :data="payments_list" border stripe>
        <el-table-column width="150px" label="付款凭证">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.payment_evidence"
              :preview-src-list="[scope.row.payment_evidence]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="付款金额">
          <template slot-scope="scope">
            <el-tag type="success">¥{{ $round(scope.row.amount) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="150px" label="付款方式">
          <template slot-scope="scope">
            {{ scope.row.method == "offline" ? "线下支付" : "在线支付"}}
          </template>
        </el-table-column>
        <el-table-column
          width="200px"
          label="付款时间"
        >
        <template slot-scope="scope">
           {{ scope.row.payment_time ? new Date(scope.row.payment_time).Format("yyyy年MM月dd日 HH:mm:ss") : "--" }}
        </template>
      </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">{{ scope.row.remark  }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import cityData from "@/config/citydata.js";
import { shopOrderList, expressList } from "@/config/config.js";
import { shopOrderStatus } from "@/config/index.js";
import AmapChose from "@/components/amap-choose/amap-choose.vue";
import upload from "@/components/upload";
import { myMixins } from "@/mixins/myMixins.js";
export default {
  components: {
    AmapChose,
    upload,
  },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      // 订单列表查询参数
      queryInfo: {
        action: "goodsOrderList",
        query: "",
        status: -1,
        page: 1,
        limit: 10,
        path: "/ashx/goods.ashx",
      },
      total: 0,
      // 订单列表
      list: [],
      // 修改地址对话框
      addressForm: {
        expressId: "",
        expressNo: "",
      },

      addressFormRules: {
        address1: [
          {
            required: true,
            message: "请选择省市区县",
            trigger: "blur",
          },
        ],
        address2: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
      },
      cityData,
      // 物流进度对话框
      progressDialogVisible: false,
      // 物流进度
      progressInfo: [],
      keyMap: "88ff4caf7acbe262dc2fe98e757cb8be",
      keyWeb: "7094d6e6f20ede19da379b01d7cf4dda",
      initLocation: "116.787141,32.573306",
      color: "#232323",
      currentOrder: null,
      mapShow: true,
      initFileList: [],
      offlinePayForm:{
        order_no:"",
        payment_evidence:"",
        remark:""
      },
      offlinePayDialogVisible: false,
      offlinePayEvidenceDialogVisible: false,
      payments_list: [],
      updateProgressForm: {
        number: "",
        image: "",
        deliverName: "",
        mobile: "",
        deliverDate: "",
      },
      deliverImages: [],
      updateProgressDialogVisible: false,
      isMultiple: true,
      deliverProgressDialogVisible: false,
      deliverProgressList: [],
      initLocation: "",
      addressDialogVisible: false,
      tempAddressInfo: {},
      mapDialogTitle: "选择地址",
      onlyShowMap: false,
    };
  },
  computed: {
    expressList() {
      return expressList;
    },
  },
  created() {
    let that = this;
    this.getList();
  },
  mounted() {

  },
  methods: {
    getStatus(status) {
      return shopOrderStatus[status];
    },
    getFullAddress(addressObj) {
      return (
        addressObj.provinceStr +
        addressObj.cityStr +
        addressObj.areaStr +
        addressObj.street
      );
    },
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      // params.page--;
      const { data: res } = await this.$http.get("/web_api/shop/order_list", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单列表失败！");
      }
      this.list = res.data.map((item)=>{
          item.payments =  item.payments && item.payments.length ? JSON.parse(item.payments) : [];
          return item;
      });
      this.total = res.total || this.list.length;
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getList();
    },
    changeAddress(row) {
      this.currentOrder = row;
      this.addressDialogVisible = true;
    },
    deliver(row) {
      this.currentOrder = row;
    },
    addressDialogClosed() {
      this.$refs.addressFormRef.resetFields();
    },
    openLocation() {},
    async showProgressDialog() {
      this.progressDialogVisible = true;
      return;
      // 供测试的物流单号：1106975712662
      const { data: res } = await this.$http.get("/kuaidi/1106975712662");
      if (res.meta.status !== 200) {
        return this.$message.error("获取物流进度失败!");
      }
      this.progressInfo = res.data;
      this.progressDialogVisible = true;
    },
    changeShow(status) {
      // this.addressDialogVisible = status;
      // this.goBack();
    },
    placeChoose(info) {
      console.log(info);
      // this.$setGlobalData("choosedLocation", info)
      // this.goBack();
    },
    offlinePay(row) {
      this.currentOrder = row;
      this.offlinePayDialogVisible = true;
      this.offlinePayForm.order_no =  row.shop_orderNo;
    },
    handleRemove(response) {
      if (response && response.name == "pay_proof_image") {
        this.offlinePayForm.payment_evidence = "";
      } else if (response && response.name == "deliver_proof_image") {
        console.log(response);
        this.deliverImages = response.value.map((item) => {
          return item.url;
        });
      }
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      console.log("--------------");
      console.log(response);
      if (response && response.name == "deliver_proof_image") {
        this.deliverImages = response.value.map((item) => {
          return item.url;
        });
      }else  if (response && response.name == "pay_proof_image") {
        let images = response.value.map((item) => {
          return item.url;
        });
        this.offlinePayForm.payment_evidence = images.join(",");
      }
    },
    offlinePayDialogClosed(){
      this.offlinePayForm = {};
      this.offlinePayDialogVisible = false;
    },
    async offlinePayConfirm() {
      if(!this.offlinePayForm.payment_evidence){
        return this.$message.error("请上传支付凭证");
      }
      let params = _.cloneDeep(this.offlinePayForm);
      const { data: res } = await this.$http.post("web_api/shop/offline_pay", params);
      if (res.code !== 200) {
        return this.$message.error("操作失败！");
      }
      this.$message.success("操作成功！");
      this.currentOrder = null;
      this.offlinePayForm = {};
      this.offlinePayDialogVisible = false;
      this.getList();
    },

    viewOfflinePayEvidence(row){
      this.offlinePayEvidenceDialogVisible = true;
      this.payments_list =  row.payments;
    },

    updateStatus(row, status) {
      let title;
      switch (status) {
        case 1:
          title = "是否确认接受此订单";
          break;
        case 2:
          title = "是否确认开始配送订单";
          break;
        case 3:
          title = "是否确认完结该订单, 完结后将不能再更新配送进度";
          break;
        case -99:
          title = "是否确认取消该订单";
          break;
      }
      if (status == 3 && row.deliveredNumber == 0) {
        title =
          "当前合计配送数量为0，是否确认完结此订单，完结后将不能再更新配送进度";
      }
      this.$confirm(title, "温馨提示", {
        confirmButtonText: "确定",
        callback: async (action) => {
          if (action == "confirm") {
            const { data: res } = await this.$http.post("openapi/proxy_post", {
              action: "goodsOrderStatusUpdate",
              path: "ashx/goods.ashx",
              status,
              orderNo: row.shop_orderNo,
            });
            if (res.code !== 200) {
              return this.$message.error("操作失败！");
            }
            this.$message.success("操作成功！");
            this.getList();
          }
        },
      });
    },
    updateDeliverProgress(row) {
      this.currentOrder = row;
      this.updateProgressDialogVisible = true;
    },
    async updateDeliverProgressConfirm() {
      if (!this.deliverImages.length) {
        return this.$message.error("请上传凭证！");
      }
      if(this.updateProgressForm.number <= 0){
        return this.$message.error("请输入正确的配送数量");
      }
      if (
        this.updateProgressForm.number >
        this.currentOrder.number - this.currentOrder.deliveredNumber
      ) {
        return this.$message.error("超出剩余应配送数量");
      }
      let userId = this.$getUserId();
      let params = _.cloneDeep(this.updateProgressForm);
      params.path = "ashxWeb/web_goods.ashx";
      params.action = "shop_orderDeliverList_Add";
      // params.userid = userId;
      params.orderNo = this.currentOrder.shop_orderNo;
      params.image = this.deliverImages.join(",");
      const { data: res } = await this.$http.post("openapi/proxy_post", params);
      if (res.code !== 200) {
        return this.$message.error("操作失败！");
      }
      this.$message.success("操作成功！");
      this.currentOrder = null;
      this.updateProgressDialogVisible = false;
      this.getList();
    },
    viewDeliverList(row) {
      this.currentOrder = row;
      this.getDeliverList();
    },
    async getDeliverList() {
      const { data: res } = await this.$http.get("/openapi/web_proxy_get", {
        params: {
          action: "shop_orderDeliverList",
          path: "ashxWeb/web_goods.ashx",
          orderNo: this.currentOrder.shop_orderNo,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单服务列表失败！");
      }
      console.log(res.data);
      this.deliverProgressList = [];
      res.data.forEach((element) => {
        element.images = element.image.split(",");
        this.deliverProgressList.push(element);
      });
      this.deliverProgressDialogVisible = true;
    },
    viewLocation(row) {
      this.initLocation = `${row.lng}, ${row.lat}`;
      this.mapDialogTitle = "查看位置";
      this.onlyShowMap = true;
      this.addressDialogVisible = true;
    },
    cancelChooseAddress() {
      this.addressDialogVisible = false;
    },
    confirmChooseAddress() {
      this.$refs["mapChoose"].confirm();
      this.addressDialogVisible = false;
    },
    addressDialogClosed() {
      this.addressDialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
